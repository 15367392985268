
import { mapGetters } from 'vuex';
import UFuzzy from '@leeoniya/ufuzzy';

import uiMixin from '../mixins/ui';

const uf = new UFuzzy({});

export default {
  name: 'ActionsView',
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      searchText: '',
      searchIncludesDone: false,
    };
  },
  computed: {
    ...mapGetters('actionsUser', [
      'itemsByRankIncomplete',
      'valuePotentialTotal',
      'valueTotal',
    ]),
    searchTextIsValid() {
      return typeof this.searchText === 'string' && this.searchText.length >= 3;
    },
    actions() {
      let actions;

      if (this.searchText) {
        actions = this.matchingActions;
      } else {
        actions = this.itemsByRankIncomplete.slice(0, 10);
      }

      return actions;
    },
    matchingActions() {
      if (!this.searchTextIsValid) {
        return [];
      }

      this.$store.dispatch('logEvent', { name: 'search' });

      const actions = [];
      const haystack = this.$store.state.actionsUser.all.map(i => this.getActionSearchStr(i));
      const needle = this.searchText.toLowerCase();
      const idxs = uf.filter(
        haystack,
        needle,
      );

      if (idxs.length < 1000) {
        const info = uf.info(idxs, haystack, needle);

        // order is a double-indirection array (a re-order of the passed-in idxs)
        // this allows corresponding info to be grabbed directly by idx, if needed
        const order = uf.sort(info, haystack, needle);

        // render post-filtered & ordered matches
        for (let i = 0; i < order.length; i++) {
          // using info.idx here instead of idxs because uf.info() may have
          // further reduced the initial idxs based on prefix/suffix rules
          actions.push(this.$store.state.actionsUser.all[info.idx[order[i]]]);
        }
      } else {
        // render pre-filtered but unordered matches
        for (let i = 0; i < idxs.length; i++) {
          actions.push(this.$store.state.actionsUser.all[idxs[i]]);
        }
      }

      return actions;
    },
  },
  mounted() {
    this.$bindKeys.bind('actions', {
      Escape: () => {
        // This really shouldn't be aware that it's in a modal, but we need to escape :P
        this.$store.dispatch('ui/hideModal', 'actions');
      },
    }, true);
  },
  destroyed() {
    this.$bindKeys.unbind('actions');
  },
  methods: {
    handleSearchText(e) {
      if (e?.target?.value) {
        this.searchText = e.target.value.trim();
      } else {
        this.searchText = '';
      }
    },
    getActionSearchStr(action) {
      const searchVals = [action.description];

      if (action.link) {
        searchVals.push(action.link);
      }

      if (action.interest && action.interest.length > 0) {
        searchVals.push(this.getInterestNamesForAction(action));
      }

      if (this.$store.getters['actionsUser/isItemFrequencyRepeating'](action)) {
        searchVals.push('repeating every'); // helps a generic search
        searchVals.push(this.$store.getters['actionsUser/getItemFrequencyDisplay'](action));
      }

      return searchVals.join(' ');
    },
    getInterestNamesForAction(action) {
      const names = [];

      if (action.interest && action.interest.length > 0) {
        action.interest.forEach((id) => {
          const interest = this.$store.state.interests.all.find(i => id === i.id);

          if (interest && interest.name) {
            names.push(interest.name);
          }
        });
      }

      return names.join(', ');
    },
    handleActionSelected(items, actionId) {
      // pass all items and set activeIndex to actionId index
      this.uiShowActionSelector(items.map(i => i.id), actionId);
    },
  },
};
