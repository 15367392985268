import { render, staticRenderFns } from "./actions.vue?vue&type=template&id=4725a280&"
import script from "./actions.vue?vue&type=script&lang=js&"
export * from "./actions.vue?vue&type=script&lang=js&"
import style0 from "./actions.vue?vue&type=style&index=0&id=4725a280&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Value: require('/opt/build/repo/components/value.vue').default,ActionsDataView: require('/opt/build/repo/components/actions-data-view.vue').default})
